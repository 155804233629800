













import { Vue, Component, Prop, Model, Watch } from "vue-property-decorator";

@Component({})
export default class InputSwitch extends Vue {
  @Model("change", { type: Boolean }) public readonly checked!: boolean;
  @Prop({ type: Boolean, default: false }) public disabled!: string;
  @Prop({ type: String }) public name!: string;

  private inputChecked: boolean = this.checked || false;

  public onChange(evt: Event) {
    this.toggle(evt);
  }

  public toggle(evt?: Event) {
    this.inputChecked = !this.inputChecked;
    this.$emit("change", this.inputChecked);
    this.$emit("change-event", evt);
  }

  public mounted() {
    if (this.checked && !this.inputChecked) {
      this.toggle();
    }
  }

  @Watch("checked")
  private checkedHandler(newValue: boolean) {
    if (newValue !== this.inputChecked) {
      this.toggle();
    }
  }
}

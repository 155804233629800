






























































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { mapGetters, mapActions, mapState } from "vuex";
import { TweenLite } from "gsap";
import anime from "animejs";
import { isEmpty } from "lodash";

import InputSwitch from "@/components/UI/form/InputSwitch.vue";
import Form from "@/components/UI/form/Form.vue";
import Button from "@/components/UI/Button.vue";
import Checkbox from "@/components/UI/form/Checkbox.vue";
import Radio from "@/components/UI/form/Radio.vue";

import { Subitem, Product } from "../store/modules/products/interfaces/products.state";

@Component({
  computed: {
    ...mapState("application", ["isEdit", "isFormValid","isSubmited"]),
    ...mapState("products", ["products", "productsSelected"]),
    ...mapGetters("products", ["getPrice", "getResultPrice","isAvailable"]),
    ...mapGetters("application", ["getVisibleBodyForm", "getApplicationInfo"])
  },
  methods: {
    ...mapActions("products", ["fetchProducts", "toggleProductsSelected"]),
    ...mapActions("application", ["submitHandler"])
  },
  components: { InputSwitch, Form, Button, Checkbox, Radio }
})
export default class Products extends Vue {
  public fetchProducts!: () => void;
  public getResultPrice!: number;
  public isEdit!: null | boolean;
  public isSubmited!: null |boolean;
  private subitemOpen: { [key:number]:boolean } = {};
  private productsSelected!: number[];
  private products!: Product[];
  private tl!: TweenLite;
  private toggleProductsSelected!: ({
    id,
    checked
  }: {
    id: number;
    checked: boolean;
  }) => void;

  public animateSubitem() {
    this.products.forEach(({id, subitems}) => {
      if (subitems) {
        const isProductChecked = this.isProductChecked(id);
        const elem = (this.$refs[id] as (Element & { isHide: boolean })[])[0];
        const elementSubitems = elem.querySelector(
          ".product-form-table-item__subitems"
        );

        if (elem && elem.isHide !== isProductChecked) {
          const isOpen = elem.isHide = isProductChecked;

          const tl = new TweenLite(elementSubitems, 0.8, {
            height: isOpen ? "auto" : 0
          })

          tl.eventCallback("onStart", () => {
            elem.classList.add("is-animation");
          });

          tl.eventCallback("onComplete", () => {
            elem.classList.remove("is-animation");

            if (isOpen) {
              elem.classList.remove("is-hidden");
            } else {
              elem.classList.add("is-hidden");
            }
          });
        }
      }
    });
  }

  public result(animate: boolean = true) {
    const elemResult: any = this.$refs["result-price"];

    if (elemResult) {
      if (animate) {
        this.tl = new TweenLite(elemResult, 1, {
          roundProps: "innerText",
          innerText: this.getResultPrice
        });
      } else {
        elemResult.innerText = this.getResultPrice;
      }
    }
  }

  public created() {
    if (this.isEdit === null && this.$route.params.orderId === undefined) {
      this.fetchProducts();
    }
  }

  public mounted() {
    this.animateSubitem();
    this.result(false);
  }

  public beforeDestroy() {
    if (this.tl) {
      this.tl.kill();
    }
  }

  public updated() {
    this.result(true);
    this.animateSubitem();
  }

  private isProductChecked(productID: number) {
    return this.productsSelected.some(id => id === productID);
  }

  private handlerChange(id: number, subitems: Subitem[]) {
    subitems.forEach(subitem => {
      this.toggleProductsSelected({ id: subitem.id, checked: false });
    });

    this.toggleProductsSelected({ id, checked: true });
  }
}

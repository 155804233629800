








































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { isEmpty } from "lodash";

import Form from "@/components/UI/form/Form.vue";
import FormBox from "@/components/UI/form/FormBox.vue";
import Input from "@/components/UI/form/Input.vue";
import Select from "@/components/UI/form/Select.vue";
import TextArea from "@/components/UI/form/TextArea.vue";
import Checkbox from "@/components/UI/form/Checkbox.vue";
import InputRadio from "@/components/UI/form/InputRadio.vue";
import IconBase from "@/components/UI/IconBase.vue";
import Comp from "@/components/UI/icon/Comp.vue";
import Usb from "@/components/UI/icon/Usb.vue";
import Archive from "@/components/UI/icon/Archive.vue";
import FormItem from "@/components/UI/form/FormItem.vue";
import Button from "@/components/UI/Button.vue";
import FnsExistingCertList from "@/components/FnsExistingCertList.vue";

@Component({
  computed: {
    ...mapState("application", [
      "formControls",
      "isFormValid",
      "optionsCountry",
      "optionsRegion",
      "optionsFilial",
      "isEdit",
      "creationDate"//,
      // "changeDate",
      // "externalSourcePkcs10",
      // "archived"
    ]),
    ...mapGetters("application", ["getVisibleBodyForm", "getApplicationInfo",
      "getFnsExistingCerts"])
  },
  methods: {
    ...mapActions("application", [
      "getReferenceCountries",
      "getReferenceRegions",
      "getReferenceFilials",
      "inputHandler",
      "typeSelectionHandler",
      "nextActiveTab",
      "checkValidity"//,
      // "autoFillFields"
    ])
  },
  components: {
    Form,
    FormBox,
    Input,
    TextArea,
    InputRadio,
    Select,
    FormItem,
    Checkbox,
    Button,
    IconBase,
    Comp,
    Usb,
    FnsExistingCertList,
    Archive
  }
})
export default class ProposalForm extends Vue {
  // public get isIconStatus() {
  //   return this.externalSourcePkcs10 || this.archived;
  // }

  public get addresTitle() {
    if (this.formControls.typeEntre.value === "ur") {
      return "Юридический адрес";
    }
    return "Фактический адрес";
  }

  public getReferenceCountries!: () => void;
  public getReferenceRegions!: () => void;
  public getProducts!: () => void;
  public formControls!: any;
  public isEdit!: null | boolean;
  public isFormValid!: boolean;
  public checkValidity!: () => void;
  public nextActiveTab!: (secrollUp: boolean) => void;
  public getReferenceFilials!: () => void;

  // private externalSourcePkcs10!: boolean;
  // private archived!: boolean;

  public mounted() {
    this.getReferenceRegions();
    this.getReferenceFilials();
  }

  private nextTab() {
    this.checkValidity();

    if (this.isFormValid) {
      this.nextActiveTab(true);
    }
  }
}

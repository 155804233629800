





import { Vue, Component, Prop } from "vue-property-decorator";

interface TabProps {
  title: string;
  disabled: boolean;
}

@Component({ name: "Tab" })
export default class Tab extends Vue {
  @Prop({ type: String, required: true }) public readonly title!: string;
  @Prop({ type: Boolean, default: false }) public readonly disabled!: boolean;

  public isActive = false;
}

















import { Vue, Component, Prop, Model, Watch } from "vue-property-decorator";

@Component({ name: "Radio" })
export default class Radio extends Vue {
  @Model("change", { type: Boolean }) public readonly checked!: boolean;
  @Prop({ type: [String] }) private readonly name!: string;
  @Prop({ type: [Number, String] }) private readonly value?: string | number;
  @Prop({ type: [String] }) private readonly label?: string;
  @Prop({ type: [Boolean] }) private readonly disabled?: boolean;

  private get state() {
    return this.checked || false;
  }

  public onChange(evt: Event) {
    this.toggle(evt);
  }

  public toggle(evt?: Event) {
    const checked = !this.state;
    this.$emit("change", checked);
    this.$emit("change-event", evt);
  }

  public mounted() {
    if (this.checked && !this.state) {
      this.toggle();
    }
  }

  @Watch("checked")
  private checkedHandler(newValue: boolean) {
    if (newValue !== this.state) {
      this.toggle();
    }
  }
}

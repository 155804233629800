


















import Vue from "vue";

export default Vue.extend({
  name: "FnsExistingCertList",
  props: {
    certList: {
      type: Array,
      required: true,
      default: []
    }
  }
})

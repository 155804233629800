





























































































import { Vue, Component, Prop } from "vue-property-decorator";
import { mapState, mapActions, mapGetters } from "vuex";

import ProposalForm from "@/components/ProposalForm.vue";
// import ProposalFormDocument from "@/components/ProposalFormDocument.vue";
import ItemDocument from "@/components/ItemDocument.vue";
import Button from "@/components/UI/Button.vue";
import IconBase from "@/components/UI/IconBase.vue";
import Cert from "@/components/UI/icon/Cert.vue";
import NotCert from "@/components/UI/icon/NotCert.vue";
import Products from "@/components/Products.vue";
import { Tab, Tabs } from "@/components/Tabs";
import CryptoProWrapper from '../services/CryptoProWrapper/CryptoProWrapper';
import { InitialFileTypes } from '@/constants/InitialFileTypes';
import { IDocInfo } from '../store/modules/application/interfaces/default-upload-form.interface';

@Component({
  computed: {
    ...mapState("application", [
      // "isDocumentDownload",
      // "filesDownload",
      "activeTab",
      // "isDocumentsCollected",
      // "isCertificateRequested",
      // "certificate",
      "statusId",
      "isEdit",
      "isFormValid"
    ]),
    // ...mapGetters("application", ["getUpload"])
  },
  methods: {
    ...mapActions("application", [
      "setActiveTab",
      // "genRequestFile",
      // "downloadHandler",
      // "downloadRaw",
      // "install"
    ])
  },
  components: {
    ProposalForm,
    // ProposalFormDocument,
    Products,
    Button,
    // ItemDocument,
    IconBase,
    Cert,
    NotCert,
    Tabs,
    Tab
  }
})
export default class FormRequest extends Vue {
  // public filesDownload!: [];
  // public certificate!: IDocInfo[];
  public tabs = [
    { name: "Форма предзаявки" },
    { name: "Продукты" }//,
    // { name: "Документы (система)" },
    // { name: "Документы (клиент)" },
    // { name: "Сертификат" }
  ];

  // public download = [
  //   {
  //     typeId: 0,
  //     // img: "/img/doc.svg",
  //     type: "doc",
  //     title: "Запрос",
  //     descr: "сертификата"
  //   },
  //   {
  //     typeId: 1,
  //     // img: "/img/doc.svg",
  //     type: "doc",
  //     title: "Заявление",
  //     descr: "№ %% на выпуск ЭП"
  //   },
  //   {
  //     typeId: 2,
  //     // img: "/img/doc.svg",
  //     type: "doc",
  //     title: "Доверенность",
  //     descr: ""
  //   },
  //   {
  //     typeId: 3,
  //     // img: "/img/doc-score.svg",
  //     type: "doc-score",
  //     title: "Счет",
  //     descr: "№ %% на выпуск ЭП"
  //   },
  //   {
  //     typeId: 4,
  //     // img: "/img/doc.svg",
  //     type: "doc",
  //     title: "Универсальный передаточный документ",
  //     descr: ""
  //   },
  //   {
  //     typeId: 5,
  //     // img: "/img/doc-key.svg",
  //     type: "doc",
  //     title: "Клиентский договор",
  //     descr: ""
  //   },
  //   {
  //     typeId: 6,
  //     // img: "/img/doc.svg",
  //     type: "doc",
  //     title: "Печатная форма",
  //     descr: "СКП ЭП"
  //   },
  //   {
  //     typeId: 7,
  //     // img: "/img/doс-cert.svg",
  //     type: "doc-cert",
  //     title: "Сертификат ключа",
  //     descr: ""
  //   }
  // ];

  // private downloadRaw!: (docInfo: IDocInfo) => string;

  // private install!: (certificate: string) => void;

  // get getFilesDownload() {
  //   return (arr: []) =>
  //     arr.map((file: any) => {
  //       for (const doc of this.download) {
  //         if (doc.typeId === file.typeId) {
  //           const { descr, ...download } = doc;
  //           return {
  //             ...download,
  //             ...file,
  //             descr: descr.replace("%%", file.orderId)
  //           };
  //         }
  //       }
  //     });
  // }

  // private isProductInOrder(docInfos: IDocInfo[], type: InitialFileTypes): boolean {
  //   return this.getInfoDoc(docInfos, type) != undefined;
  // }

  // private getInfoDoc(docInfos: IDocInfo[], type: InitialFileTypes) : any {
  //   return docInfos.find(d => d.typeId == type);
  // }

  // private async installCertificate(docInfo: IDocInfo) {
  //   const certificate = await this.downloadRaw(docInfo);

  //   if (certificate.length > 0) {
  //     await this.install(certificate);      
  //   }
  // }

  // private getInitialFileTypes() {
  //   return InitialFileTypes;
  // }

  // private getLicenses() {
  //   console.log('in getLicenses');
  //   const licenses = this.certificate.filter(c => c.licenseType != null);
  //   return licenses;
  // }
}




























































import { Vue, Component, Prop } from "vue-property-decorator";
import { mapActions, mapState } from "vuex";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PageLayout from "@/components/layouts/PageLayout.vue";
import FormRequest from "@/components/FormRequest.vue";
import Button from "@/components/UI/Button.vue";
import ArrowLeft from "@/components/UI/icon/ArrowLeft.vue";
import Pencil from "@/components/UI/icon/Pencil.vue";
import Comment from "@/components/UI/icon/Comment.vue";
import IconBase from "@/components/UI/IconBase.vue";

@Component({
  computed: {
    ...mapState("application", [
      "isEdit",
      "activeTab",
      "isEditUpload",
      // "certificate",
      "comment",
      "statusId"//,
      // "isCertificateRequested"
    ]),
    ...mapState("applicationList", ["isArchive"])
  },
  methods: {
    ...mapActions("application", [
      "clearFromControlsHandler",
      "getApplication",
      "edit"//,
      // "sendToArchive",
      // "restoreFromArchive"
    ]),
    ...mapActions("products", ["resetProducts"])
  },
  components: {
    FormRequest,
    Button,
    ArrowLeft,
    Pencil,
    IconBase,
    Comment,
    PageLayout,
    Header,
    Footer
  }
})
export default class Application extends Vue {
  public clearFromControlsHandler!: () => void;
  public getApplication!: (id: any) => void;
  public edit!: () => void;
  // public sendToArchive!: () => void;
  // public restoreFromArchive!: () => void;
  public resetProducts!: () => void;

  public mounted() {
    const { orderId } = this.$route.params;

    if (orderId) {
      this.getApplication(orderId);
    }
  }

  public destroyed() {
    this.clearFromControlsHandler();
    this.resetProducts();
  }

  
  private acceptEdit(activeTab: number) {
    this.edit();
    // if (/(1|2)/.test(activeTab.toString())) {
    //   this.$modal.show("dialog", {
    //     title: "Внимание",
    //     text:
    //       "Изменение полей предзаявки приведет к аннулированию текущей версии предзаявки. Вам потребуется заново приложить пакет документов. Продолжить?",
    //     buttons: [
    //       {
    //         title: "Отмена"
    //       },
    //       {
    //         title: "ПРОДОЛЖИТЬ",
    //         default: true,
    //         handler: () => {
    //           this.edit();

    //           this.$modal.hide("dialog");
    //         }
    //       }
    //     ]
    //   });
    // }

    // if (activeTab === 3) {
    //   this.$modal.show("dialog", {
    //     title: "Внимание",
    //     text:
    //       "Вам потребуется заново приложить весь пакет документов. Продолжить?",
    //     buttons: [
    //       {
    //         title: "Отмена"
    //       },
    //       {
    //         title: "ПРОДОЛЖИТЬ",
    //         default: true,
    //         handler: () => {
    //           this.edit();
    //           this.$modal.hide("dialog");
    //         }
    //       }
    //     ]
    //   });
    // }
  }
}
